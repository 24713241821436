import React from 'react'

import { Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Section } from '../../components/Section'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock, LinkMockTargetBlank } from '../../components/LinkMock'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import placeholder2 from '../../assets/placeholder-2.jpg'
import suomifi from '../../../../lib/assets/suomifi.svg'

const Page = () => (
  <Content
    heading="ThemeCard"
    subHeading="Verkkopalvelu"
  >
    <Section title="Primary-värisellä taustalla">
      <Playground format="html">
        <article className="theme-card">
          <div
            className="theme-card-image"
            style={{ backgroundImage: `url(${placeholder2})` }}
          />
          <div className="theme-card-content">
            <h3 className="h1">Heading</h3>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum culpa
              in dolor tempore dolorum dolore quasi veniam ipsa debitis quaerat.
            </p>
            <ButtonHTMLExample
              as={LinkMock}
              color="white"
              iconRight={<IconHTMLExample name="ChevronRightIcon" />}
              variant="outline"
            >
              Lorem ipsum
            </ButtonHTMLExample>
          </div>
        </article>
      </Playground>
    </Section>

    <Section title="Valkoisella taustalla">
      <Playground format="html">
        <article className="theme-card theme-card--white">
          <div
            className="theme-card-image theme-card-image--white"
            style={{ backgroundImage: `url(${placeholder2})` }}
          />
          <div className="theme-card-content">
            <h3 className="h1">Heading</h3>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum culpa
              in dolor tempore dolorum dolore quasi veniam ipsa debitis quaerat.
            </p>
            <ButtonHTMLExample
              as={LinkMock}
              color="primary"
              iconRight={<IconHTMLExample name="ChevronRightIcon" />}
              variant="outline"
            >
              Lorem ipsum
            </ButtonHTMLExample>
          </div>
        </article>
      </Playground>
    </Section>

    <Section title="Valkoisella taustalla ja ulkoisella linkillä">
      <Paragraph>
        Huomaa <Code>external-link</Code>-ikoni ja linkin{' '}
        <Code>target=&quot;_blank&quot;</Code>
      </Paragraph>

      <Playground format="html">
        <article className="theme-card theme-card--white">
          <div
            className="theme-card-image theme-card-image--white"
            style={{
              background: `url(${suomifi}) 60% 50% no-repeat, radial-gradient(42.49% 63.74% at 50% 50%, #2C6EBB 0%, #235B9C 100%)`
            }}
          />
          <div className="theme-card-content">
            <h3 className="h1">Heading</h3>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum culpa
              in dolor tempore dolorum dolore quasi veniam ipsa debitis quaerat.
            </p>
            <ButtonHTMLExample
              as={LinkMockTargetBlank}
              color="primary"
              iconRight={
                <IconHTMLExample
                  name="ExternalLinkIcon"
                  ariaLabel="avautuu uuteen välilehteen"
                />
              }
              variant="outline"
            >
              Lorem ipsum
            </ButtonHTMLExample>
          </div>
        </article>
      </Playground>
    </Section>
  </Content>
)

export default Page
